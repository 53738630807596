








































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  CmsCategoryCreateOrUpdateDto,
  ProductCategoryDto,
  ProductDto,
  ProductSkuDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage.vue";
import Ueditor from "@/components/Ueditor.vue";
import { createNgTree, INgNode } from "@/utils/tree";
import SelectOrderCommodity from "@/views/productSku/order/selectOrderCommodity.vue";

interface CmsCategoryExtendCreateOrUpdateDto
  extends CmsCategoryCreateOrUpdateDto {
  categoryIds: number[];
}

@Component({
  components: { SimpleUploadImage, SelectOrderCommodity, Ueditor },
})
export default class CreateCmsCategory extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: CmsCategoryExtendCreateOrUpdateDto = {
    id: 0,
    displayName: undefined,
    displayNameFr: undefined,
    categoryCode: undefined,
    description: undefined,
    parentId: undefined,
    isShow: undefined,
    titleImagePath: undefined,
    isNavigation: true,
    categoryIds: [],
  };
  index = 0;
  categoryList = [] as ProductCategoryDto[];
  categoryTree: INgNode<ProductCategoryDto>[] = [];
  classificationTree: INgNode<ProductCategoryDto>[] = [];
  classificationList = [] as ProductCategoryDto[];
  productList: ProductDto[] = [];
  categoryIdArray: number[] = [];
  isNavDefault = false;
  roleRule = {
    displayName: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "cmsNavigation.tips.displayName"
        ),
        trigger: "blur",
      },
    ],
    displayNameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "cmsNavigation.tips.displayNameFr"
        ),
        trigger: "blur",
      },
    ],
    categoryCode: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "cmsNavigation.enterNavigationCode"
        ),
        trigger: "blur",
      },
    ],
  };

  get productIdList() {
    return this.productList.map((item: ProductDto) => item.id);
  }

  get hostType() {
    return AttachmentHostType.CmsCategory;
  }

  get pageHeadTitle() {
    if (this.$route.params.id) {
      return (this as any).$l.getLanguageText(
        "cmsNavigation.editSubNavigation"
      );
    } else {
      return (this as any).$l.getLanguageText(
        "cmsNavigation.createSubNavigation"
      );
    }
  }

  created() {
    if (this.$route.params.parentId) {
      this.form.parentId = Number(this.$route.params.parentId);
    }
    if (this.$route.query.isNavDefault) {
      this.isNavDefault = this.$route.query.isNavDefault === "true";
    }
    if (this.$route.params.id) {
      let _this = this as any;
      api.cmsCategory.get({ id: Number(this.$route.params.id) }).then((res) => {
        this.form = { ...this.form, ...res };
        res.productCategoryList?.forEach((item) => {
          this.categoryIdArray.push(Number(item.productCategoryId));
        });
        this.form.categoryIds = this.categoryIdArray;
        res.productList?.forEach((item) => {
          this.productList?.push({
            name: item.fkProduct?.name,
            nameFr: item.fkProduct?.nameFr,
            fullName: item.fkProduct?.fullName,
            fkBrand: item.fkProduct?.fkBrand,
            fkCategory: item.fkProduct?.fkCategory,
            totalStock: item.fkProduct?.totalStock,
            id: item.productId,
          });
        });
      });
    }
    this.fetchClassificationList();
    this.$forceUpdate();
  }

  //获取全部商品类别
  async fetchClassificationList() {
    await api.productCategory
      .getAll({
        skipCount: 0,
        maxResultCount: 65535,
      })
      .then((res) => {
        this.categoryList = res.items ?? [];
        this.classificationList =
          res.items?.filter((item: ProductCategoryDto) => item.level !== 3) ??
          [];
        this.categoryTree = createNgTree(
          res.items ?? [],
          "parentId",
          "id",
          null,
          "children",
          null,
          false,
          "id",
          "fullName"
        );
        console.log(this.categoryTree);
        let defaultData = [
          {
            children: undefined,
            id: 0,
            key: "",
            label: "一级分类",
            parentNode: undefined,
            isLeaf: true,
            hidden: false,
            expanded: false,
            disabled: false,
            data: {
              id: 0,
              fullName: "一级分类",
            },
          },
        ] as INgNode<ProductCategoryDto>[];
        this.classificationTree = defaultData.concat(
          createNgTree(
            res.items?.filter((item: ProductCategoryDto) => item.level !== 3) ??
              [],
            "parentId",
            "id",
            null,
            "children",
            null,
            false,
            "id",
            "fullName"
          )
        );
        console.log(this.classificationTree);
      });
  }

  // 选择商品
  handleSelectCommodity() {
    (this.$refs.selectFrom as any).show = true;
  }

  getSelectionProductSkus(productList: ProductDto[]) {
    if (this.productList && this.productList.length > 0) {
      this.productList = this.productList.concat(productList);
    } else {
      this.productList = productList;
    }
  }

  handleDelete(index: number) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.deleteTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(async () => {
      this.productList?.splice(index, 1);
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      // console.log("categoryIds=" + this.form.categoryIds);
      if (this.form.categoryIds) {
        this.form.productCategoryList = [];
        this.form.categoryIds.forEach((item) => {
          this.form.productCategoryList!.push({
            productCategoryId: Number(item),
          });
        });
      }

      if (this.productList) {
        this.form.productList = [];
        this.productList?.forEach((item) => {
          this.form.productList!.push({
            productId: item.id,
          });
        });
      }
      // this.form.productCategoryList = productCategorySelectList;
      if (this.form?.id) {
        await api.cmsCategory.update({
          body: this.form,
        });
      } else {
        if (this.form.parentId === 0) {
          this.form.parentId = undefined;
        }
        await api.cmsCategory.create({
          body: this.form,
        });
      }
      this.$router.back();
      this.$message.success(
        (this as any).$l.getLanguageText("basic.updateSuccess").toString()
      );
    });
  }

  cancel() {
    this.$router.back();
  }
}
